<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: `${page_route}${store_mode ? '' : '.view'}`,
              params: { id: id },
            }"
          />
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <v-form ref="form_data" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'GENERAL'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col cols="12" xs="12" md="6">
                    <v-text-field
                      label="Nombre*"
                      v-model="data.name"
                      :rules="rules.name"
                      type="text"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <span
                      class="text-caption"
                      v-text="'Logotipo (JPG | JPEG | PNG)'"
                    />
                    <br />
                    <input
                      accept=".jpg, .jpeg, .png"
                      label="Nombre*"
                      type="file"
                      @change="select_file($event)"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="2">
                    <v-switch
                      v-if="data.logo"
                      label="Eliminar logotipo"
                      v-model="data.delete_logo"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'DOMICILIO'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col cols="12" xs="12" md="6">
                    <v-text-field
                      label="Calle"
                      v-model="data.street"
                      :rules="rules.text100"
                      type="text"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Número exterior"
                      v-model="data.external_number"
                      :rules="rules.text20"
                      type="text"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Número interior"
                      v-model="data.internal_number"
                      :rules="rules.text20"
                      type="text"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Colonia"
                      v-model="data.suburb"
                      :rules="rules.text50"
                      type="text"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-autocomplete
                      label="Estado*"
                      v-model="data.entity_id"
                      :rules="rules.required"
                      :items="entities"
                      item-value="id"
                      :item-text="(item) => item.entity"
                      :loading="entities_loading"
                      v-on:change="getMunicipalities()"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-autocomplete
                      label="Municipio*"
                      v-model="data.municipality_id"
                      :items="municipalities"
                      item-value="id"
                      :item-text="(item) => item.municipality"
                      :loading="municipalities_loading"
                      :rules="rules.required"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Código postal"
                      v-model="data.zip"
                      :rules="rules.text5"
                      type="text"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'LEGAL'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Razón social"
                      v-model="data.legal_name"
                      :rules="rules.text100"
                      type="text"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="RFC"
                      v-model="data.code_tax"
                      :rules="rules.code_tax"
                      type="text"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Domicilio fiscal"
                      v-model="data.legal_address"
                      :rules="rules.text150"
                      type="text"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Código catálogo SAT"
                      v-model="data.product_key"
                      :rules="rules.text10"
                      type="text"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="4">
                    <v-text-field
                      label="Descripción"
                      v-model="data.description"
                      :rules="rules.text200"
                      type="text"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'CONFIGURACIÓN'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col cols="12" xs="12" md="2">
                    <v-switch
                      label="Dictamen Sinergia"
                      v-model="data.judgment"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="2">
                    <v-text-field
                      label="NET ID"
                      v-model="data.net_id"
                      min="1"
                      type="number"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="2">
                    <v-switch label="Fondo*" v-model="data.fund" />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Descuento trasladado*"
                      v-model="data.discount"
                      type="number"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Monto del fondo*"
                      v-model="data.fund_mount"
                      :rules="rules.required"
                      type="number"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="2">
                    <v-switch
                      label="Búsqueda con A. materno"
                      v-model="data.find_full_last_name"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="2">
                    <v-switch
                      label="Núm. siniestro auto"
                      v-model="data.auto_sinister_number"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="2">
                    <v-switch
                      label="Enviar e-mail cartas ingreso"
                      v-model="data.send_email_rha"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      rows="1"
                      label="Leyendas"
                      v-model="data.caption"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'REDES'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col cols="12" xs="12" md="6">
                    <v-autocomplete
                      label="Proveedores*"
                      v-model="data.provider_group_id"
                      :rules="rules.required"
                      :items="provider_groups"
                      item-value="id"
                      :item-text="(item) => item.provider_group"
                      :loading="provider_groups_loading"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="6">
                    <v-autocomplete
                      label="Médicos*"
                      v-model="data.doctor_group_id"
                      :rules="rules.required"
                      :items="doctor_groups"
                      item-value="id"
                      :item-text="(item) => item.doctor_group"
                      :loading="doctor_groups_loading"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2
                  class="text-caption"
                  v-text="'CONFIGURACIÓN DE FACTURACIÓN'"
                />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      label="Paciente"
                      v-model="data.bill_visible_insured"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      label="Hospital"
                      v-model="data.bill_visible_provider"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      label="Siniestro"
                      v-model="data.bill_visible_sinester"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      label="Carta"
                      v-model="data.bill_visible_letter_auth"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      label="Descuento"
                      v-model="data.bill_visible_discounts_amount"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      label="Deducible"
                      v-model="data.bill_visible_deductibles_amount"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      label="Coaseguro"
                      v-model="data.bill_visible_coinsurances_amount"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      label="Mostrar descuento"
                      v-model="data.bill_with_discount"
                    />
                  </v-col>
                  <!-- <v-col cols="12" xs="12" md="3">
                    <v-switch
                      label="Sumar deducible y coaseguro"
                      v-model="data.add_deductible_coinsurance "
                    />
                  </v-col> -->
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'FACTURAS'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col
                    cols="12"
                    v-for="(invoice_date, i) in data.invoice_dates"
                    :key="i"
                  >
                    <v-row v-if="invoice_date.active">
                      <v-col cols="12" xs="12" md="6">
                        <v-select
                          :label="`Día de recepción* (${i + 1})`"
                          v-model="data.invoice_dates[i].day_id"
                          :rules="rules.required"
                          :items="days"
                          item-value="id"
                          :item-text="(item) => item.day"
                          :loading="days_loading"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="5">
                        <v-text-field
                          v-model="data.invoice_dates[i].hour"
                          label="Hora*"
                          :rules="rules.hourRequired"
                          type="text"
                          @change="validateHour(data.invoice_dates[i].hour)"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        md="1"
                        class="text-center"
                        align-self="center"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              text
                              icon
                              x-small
                              color="error"
                              @click="deleteInvoiceDate(i)"
                            >
                              <v-icon medium v-text="'mdi-close'" />
                            </v-btn>
                          </template>
                          <span
                            v-text="`Eliminar día de recepción (${i + 1})`"
                          />
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      @click="addRecepcionFactura"
                      color="warning"
                      :loading="loading"
                      x-small
                      block
                      dark
                    >
                      <v-icon left v-text="'mdi-plus'" />
                      Agregar día de recepción
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'PAGO'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col
                    cols="12"
                    v-for="(invoice_pay_day, i) in data.invoice_pay_days"
                    :key="i"
                  >
                    <v-row v-if="invoice_pay_day.active">
                      <v-col cols="12" xs="12" md="11">
                        <v-text-field
                          :label="`Día* (${i + 1})`"
                          v-model="data.invoice_pay_days[i].day"
                          :rules="rules.required"
                          type="number"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        md="1"
                        class="text-center"
                        align-self="center"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              text
                              icon
                              x-small
                              color="error"
                              @click="deleteInvoicePayDay(i)"
                            >
                              <v-icon medium v-text="'mdi-close'" />
                            </v-btn>
                          </template>
                          <span v-text="`Eliminar día de pago (${i + 1})`" />
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      @click="addFechaPago"
                      color="warning"
                      :loading="loading"
                      x-small
                      block
                      dark
                    >
                      <v-icon left v-text="'mdi-plus'" />
                      Agregar día de pago
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'SERVICIO'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col
                    cols="12"
                    v-for="(service, i) in data.services"
                    :key="i"
                  >
                    <v-row v-if="service.active">
                      <v-col cols="12" xs="12" md="11">
                        <v-autocomplete
                          :label="`Tipo* (${i + 1})`"
                          v-model="data.services[i].service_type_id"
                          :rules="rules.required"
                          :items="services"
                          item-value="id"
                          :item-text="(item) => item.service_type"
                          :loading="services_loading"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        md="1"
                        class="text-center"
                        align-self="center"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              text
                              icon
                              x-small
                              color="error"
                              @click="deleteService(i)"
                            >
                              <v-icon medium v-text="'mdi-close'" />
                            </v-btn>
                          </template>
                          <span
                            v-text="`Eliminar tipo de servicio (${i + 1})`"
                          />
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      @click="addServicios"
                      color="warning"
                      :loading="loading"
                      x-small
                      block
                      dark
                    >
                      <v-icon left v-text="'mdi-plus'" />
                      Agregar tipo de servicio
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'Descuentos trasladados'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col
                    cols="12"
                    v-for="(insurance_discount, i) in data.insurance_discounts"
                    :key="i"
                  >
                    <v-row v-if="insurance_discount.active">
                      <v-col cols="12" xs="12" md="6">
                        <v-autocomplete
                          :label="`Concentrador* (${i + 1})`"
                          v-model="
                            data.insurance_discounts[i].provider_brand_id
                          "
                          :items="provider_brands"
                          item-value="id"
                          :item-text="(item) => item.provider_brand"
                          :loading="provider_brands_loading"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="5">
                        <v-text-field
                          label="Descuento trasladado*"
                          v-model="data.insurance_discounts[i].discount"
                          type="number"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        md="1"
                        class="text-center"
                        align-self="center"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              text
                              icon
                              x-small
                              color="error"
                              @click="deleteInsurancesDiscount(i)"
                            >
                              <v-icon medium v-text="'mdi-close'" />
                            </v-btn>
                          </template>
                          <span
                            v-text="`Eliminar tipo de servicio (${i + 1})`"
                          />
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      @click="addInsurancesDiscount"
                      color="warning"
                      :loading="loading"
                      x-small
                      block
                      dark
                    >
                      <v-icon left v-text="'mdi-plus'" />
                      Agregar descuento de traslado
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'Zonas'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" v-for="(zone, i) in data.zones" :key="i">
                    <v-row v-if="zone.active">
                      <v-col cols="12" xs="12" md="6">
                        <v-text-field
                          :label="`Zona calle (${i + 1})`"
                          v-model="zone.street"
                          :rules="rules.text100"
                          type="text"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          label="Número exterior"
                          v-model="zone.external_number"
                          :rules="rules.text20"
                          type="text"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          label="Número interior"
                          v-model="zone.internal_number"
                          :rules="rules.text20"
                          type="text"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          label="Colonia"
                          v-model="zone.suburb"
                          :rules="rules.text50"
                          type="text"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-autocomplete
                          label="Estado*"
                          v-model="zone.entity_id"
                          :rules="rules.required"
                          :items="entities"
                          item-value="id"
                          :item-text="(item) => item.entity"
                          :loading="entities_loading"
                          v-on:change="getMunicipalitiesZone(i)"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-autocomplete
                          label="Municipio*"
                          v-model="zone.municipality_id"
                          :rules="rules.required"
                          :items="municipalities_zones[i].data"
                          item-value="id"
                          :item-text="(v) => v.municipality"
                          :loading="municipalities_zones[i].loading"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-text-field
                          label="Código postal"
                          v-model="zone.zip"
                          :rules="rules.text5"
                          type="text"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="10">
                        <v-autocomplete
                          label="Hospitales*"
                          v-model="zone.zone_providers"
                          :items="providers"
                          item-value="id"
                          :item-text="(item) => item.name"
                          multiple
                          dense
                        />
                      </v-col>

                      <v-col
                        cols="12"
                        xs="12"
                        md="1"
                        class="text-center"
                        align-self="center"
                      >
                        <v-btn
                          block
                          x-small
                          :color="zone.zone_providers.length > 0 ? '' : 'info'"
                          @click="addAllProvidersToZone(zone)"
                          >Todos</v-btn
                        >
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        md="1"
                        class="text-center"
                        align-self="center"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              text
                              icon
                              x-small
                              color="error"
                              @click="deleteZone(i)"
                            >
                              <v-icon medium v-text="'mdi-close'" />
                            </v-btn>
                          </template>
                          <span
                            v-text="`Eliminar tipo de servicio (${i + 1})`"
                          />
                        </v-tooltip>
                      </v-col>
                      <v-col cols="12">
                        <v-divider />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      @click="addZone"
                      color="warning"
                      :loading="loading"
                      x-small
                      block
                      dark
                    >
                      <v-icon left v-text="'mdi-plus'" />
                      Agregar zona
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" class="text-right">
            <BtnCircle
              :tLabel="store_mode ? 'Crear' : 'Editar'"
              tPosition="top"
              :icon="store_mode ? 'mdi-plus' : 'mdi-pencil'"
              :color="store_mode ? 'success' : 'info'"
              size="small"
              :click="handleSubmit"
              :loading="loading"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
} from "../../control";
import { catalog } from "../../requests/catalogRequest";
import { storeUpdate, show, index } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    FaqDlg,
  },
  data() {
    return {
      page_route: "aseguradoras",
      id: this.$route.params.id ? this.$route.params.id : 0,
      store_mode: this.$route.params.id ? false : true,
      login: this.$store.getters.getLogin,
      loading: false,
      data: {
        id: 0,
        name: "",
        street: "",
        external_number: "",
        internal_number: "",
        suburb: "",
        municipality: "",
        zip: "",
        entity_id: "",
        legal_name: "",
        code_tax: "",
        legal_address: "",
        discount: "0",
        judgment: false,
        fund: false,
        fund_mount: "0",
        provider_group_id: null,
        doctor_group_id: null,
        invoice_dates: [],
        invoice_pay_days: [],
        services: [],
        zones: [],
        insurance_discounts: [],
        delete_logo: false,
        net_id: null,
        find_full_last_name: false,
        auto_sinister_number: false,
        send_email_rha: false,
      },
      rules: {},
      days: [],
      days_loading: true,
      services: [],
      services_loading: true,
      provider_brands: [],
      provider_brands_loading: true,
      entities: [],
      entities_loading: true,
      municipalities: [],
      municipalities_loading: false,
      provider_groups: [],
      provider_groups_loading: true,
      doctor_groups: [],
      doctor_groups_loading: true,
      providers: [],
      municipalities_zones: [],
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    select_file(event) {
      this.data.document_file_0 = event.target.files[0];
    },
    getMunicipalities() {
      this.municipalities_loading = true;

      index("municipalities/" + this.data.entity_id, this.login.token, [
        {
          name: "active",
          value: 1,
        },
        {
          name: "id",
          value: this.login.id,
        },
      ]).then((response) => {
        this.municipalities = response.data;

        if (this.municipalities.length === 0) {
          this.municipalities.push({
            id: null,
            municipality: "NINGUNO",
            active: 1,
            entity_id: null,
          });

          this.data.municipality_id = null;
        }

        this.municipalities_loading = false;
      });
    },
    getMunicipalitiesZone(i) {
      this.municipalities_zones[i].loading = true;

      index(
        "municipalities/" + this.data.zones[i].entity_id,
        this.login.token,
        [
          {
            name: "active",
            value: 1,
          },
          {
            name: "id",
            value: this.login.id,
          },
        ]
      ).then((response) => {
        this.municipalities_zones[i].data = response.data;
        this.municipalities_zones[i].loading = false;
        this.$forceUpdate();
      });
    },
    handleSubmit() {
      if (this.$refs.form_data.validate()) {
        this.$swal
          .fire(
            msgConfirm(
              `¿Confirma la ${
                this.store_mode ? "creación" : "edición"
              } del registro?`
            )
          )
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;

              storeUpdate(
                "insurances",
                this.login.token,
                this.id,
                this.data,
                this.login.id
              ).then((response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                response.success
                  ? this.$router.push({
                      name: `${this.page_route}${
                        this.store_mode ? "" : ".view"
                      }`,
                      params: { id: this.id },
                    })
                  : console.log(response.message);
              });

              this.loading = false;
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    addAllProvidersToZone(zone) {
      if (zone.zone_providers.length === this.providers.length)
        zone.zone_providers = [];
      else zone.zone_providers = this.providers;
    },
    addRecepcionFactura() {
      this.data.invoice_dates.push({
        id: null,
        hour: "",
        active: true,
        day_id: null,
      });
    },
    addFechaPago() {
      this.data.invoice_pay_days.push({
        id: null,
        day: null,
        active: true,
      });
    },
    addServicios() {
      this.data.services.push({
        id: null,
        service_type_id: null,
        active: true,
      });
    },
    addInsurancesDiscount() {
      this.data.insurance_discounts.push({
        id: null,
        discount: null,
        provider_brand_id: null,
        active: true,
      });
    },
    addZone() {
      this.data.zones.push({
        id: null,
        street: null,
        external_number: null,
        internal_number: null,
        suburb: null,
        zip: null,
        municipality_id: null,
        zone_providers: [],
        active: true,
      });
      this.municipalities_zones.push({
        data: [],
        loading: false,
      });
    },
    deleteInvoiceDate(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar el día de recepción (${i + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            if (this.data.invoice_dates[i].id === null)
              this.data.invoice_dates.splice(i, 1);
            else this.data.invoice_dates[i].active = false;
          }
        });
    },
    deleteInvoicePayDay(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar el día de pago (${i + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            if (this.data.invoice_pay_days[i].id === null)
              this.data.invoice_pay_days.splice(i, 1);
            else this.data.invoice_pay_days[i].active = false;
          }
        });
    },
    deleteService(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar el tipo de servicio (${i + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            if (this.data.services[i].id === null)
              this.data.services.splice(i, 1);
            else this.data.services[i].active = false;
          }
        });
    },
    deleteInsurancesDiscount(i) {
      this.$swal
        .fire(
          msgConfirm(`¿Confirma eliminar el descuento transladado (${i + 1})?`)
        )
        .then((response) => {
          if (response.isConfirmed) {
            if (this.data.insurance_discounts[i].id === null)
              this.data.insurance_discounts.splice(i, 1);
            else this.data.insurance_discounts[i].active = false;
          }
        });
    },
    deleteZone(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar la zona (${i + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            if (this.data.zones[i].id === null) {
              this.data.zones.splice(i, 1);
              this.municipalities_zones.splice(i, 1);
            } else {
              this.data.zones[i].active = false;
            }
          }
        });
    },
    validateHour(time) {
      const hours = time.split(":")[0];
      const minutes = time.split(":")[1];
      if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
        this.$swal.fire(msgAlert("error", "Formato de hora incorrecto"));
      }
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    catalog("provider_brands", this.login.token).then((response) => {
      this.provider_brands = response;
      this.provider_brands_loading = false;
      this.provider_brands.push({
        id: null,
        provider_brand: "NINGUNO",
        active: true,
      });
    });

    catalog("days", this.login.token).then((response) => {
      this.days = response;
      this.days_loading = false;
    });

    catalog("service_types", this.login.token).then((response) => {
      this.services = response;
      this.services_loading = false;
    });

    catalog("entities", this.login.token).then((response) => {
      this.entities = response;
      this.entities_loading = false;
    });

    catalog("provider_groups", this.login.token).then((response) => {
      this.provider_groups = response;
      this.provider_groups_loading = false;
    });

    catalog("doctor_groups", this.login.token).then((response) => {
      this.doctor_groups = response;
      this.doctor_groups_loading = false;
    });

    show("providers/type", this.login.token, 4).then((response) => {
      this.providers = response;
    });

    if (!this.store_mode) {
      this.loading = true;

      show("insurances", this.login.token, this.id).then((response) => {
        this.data = response;
        this.data.delete_logo = false;
        this.loading = false;
        this.getMunicipalities();

        for (let i = 0; i < this.data.zones.length; i++) {
          this.municipalities_zones.push({
            data: [],
            loading: false,
          });

          this.getMunicipalitiesZone(i);
        }
      });
    }
    this.rules = rules();
  },
};
</script>